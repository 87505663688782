<template>
    <div class="dmSale">
        <!-- 头部图 -->
        <div class="dmsTop">
            <img src="https://bce-cas.bj.bcebos.com/bcd-cas-portal/domain_header_bg.png" alt="" class="dtBg" />
            <div class="dtText">
                <p class="dt1">域名委托想要服务</p>
                <p class="dt2">心仪的域名已被注册？委托专业域名经纪人想要</p>
                <div class="dt3">发布委托想要需求</div>
            </div>
        </div>
        <div class="dmsBox">
            <div class="dbTop">
                <ul>
                    <li class="dbtLi">专注精品域名回购</li>
                    <li class="dbtLi">资深域名专家一对一服务</li>
                    <li class="dbtLi">全程跟踪交易</li>
                </ul>
            </div>
            <div class="dbBox">
                <div class="dbCirl1">
                    <div class="dcT1">
                        <p class="dtp">
                            公司好不容易建立品牌，发现品牌域名早已落入他人之手，对方开出天价，奈何资金短缺，
                            中小企业创业之难谁能懂？
                        </p>
                        <div class="dts"></div>
                    </div>
                    <div class="dcT2">
                        <p class="dtp">之前低价域名没关注，现如今回购报价千百万，这个世界 没有后悔药的，只是有点贵！</p>
                        <div class="dts"></div>
                    </div>
                    <div class="dcT3">
                        <p class="dtp">看中一个域名打算投资，但已被别人抢先注册，拼手速这块妥妥输了！</p>
                        <div class="dts"></div>
                    </div>
                    <div class="dbTitle">
                        <p class="dbt">这些域名均已被注册？</p>
                        <p class="dbt">我们为您提供域名委托想要</p>
                    </div>
                </div>
                <div class="dbImgs">
                    <img src="https://bce-cas.bj.bcebos.com/bcd-cas-portal/domain_computer.png" alt="" class="dbiBg" />
                    <div class="dbi1">.vip</div>
                    <div class="dbi2">.cc</div>
                    <div class="dbi3">.cn</div>
                    <div class="dbi4">.com</div>
                    <div class="dbi5">
                        <img src="https://bce-cas.bj.bcebos.com/bcd-cas-portal/domain_shopping.svg" alt="" class="d5Img" />
                    </div>
                </div>
            </div>
        </div>
        <div class="dmsBox2">
            <div class="db2Box">
                <div class="d2bTop">
                    <p class="d2t1">我们的优势</p>
                    <p class="d2t2">我们将竭尽所能，助您获得心仪域名</p>
                </div>
                <div class="d2bList">
                    <ul>
                        <li class="d2lLi" v-for="item in 4" :key="item">
                            <div class="d2l1">
                                <img src="https://bce-cas.bj.bcebos.com/bcd-cas-portal/advance1.png" alt="" class="d2lImg" />
                            </div>
                            <div class="d2l2">
                                <p class="d2p">专业谈判团队</p>
                                <p class="d2p2">经纪团队拥有多年丰富的谈判经验，精通<br />域名行业 背景知识，优势价格购得</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="dmsBox3">
            <div class="db3Box">
                <div class="d3bTop">
                    <p class="d3t1">委托想要流程</p>
                    <p class="d3t2">标准化流程，专业可靠、品质有保证</p>
                </div>
                <div class="d3bList">
                    <ul>
                        <li class="d3lLi">
                            <div class="d3l1">
                                <img src="https://bce-cas.bj.bcebos.com/bcd-cas-portal/process1.svg" alt="" class="d3Img" />
                            </div>
                            <div class="d3l2">
                                <p class="d3p">发布委托需求</p>
                                <p class="d3p2">
                                    提交您的域名回购需求，并确保域名状态正
                                    <br />
                                    常、联系方式真实、报价合理
                                </p>
                            </div>
                            <div class="d3lx">
                                <div class="d3Icon">
                                    <img src="https://bce-cas.bj.bcebos.com/bcd-cas-portal/arrow.svg" alt="" class="d3I" />
                                </div>
                            </div>
                        </li>
                        <li class="d3lLi">
                            <div class="d3l1">
                                <img src="https://bce-cas.bj.bcebos.com/bcd-cas-portal/process2.svg" alt="" class="d3Img" />
                            </div>
                            <div class="d3l2">
                                <p class="d3p">协商谈判</p>
                                <p class="d3p2">
                                    收到需求后，专业经纪团队会第一时间联系
                                    <br />
                                    到您，分析并确认您的需求，联系威有谈判
                                    <br />
                                    协商，为您争取到最优报价
                                </p>
                            </div>
                            <div class="d3lx">
                                <div class="d3Icon">
                                    <img src="https://bce-cas.bj.bcebos.com/bcd-cas-portal/arrow.svg" alt="" class="d3I" />
                                </div>
                            </div>
                        </li>
                        <li class="d3lLi">
                            <div class="d3l1">
                                <img src="https://bce-cas.bj.bcebos.com/bcd-cas-portal/process3.svg" alt="" class="d3Img" />
                            </div>
                            <div class="d3l2">
                                <p class="d3p">交易过户</p>
                                <p class="d3p2">
                                    谈判成功后，经纪人将一对一协助买家付款
                                    <br />
                                    及域名操作过户，全程均在威有智能云平台
                                    <br />
                                    操作，确保域名及资金安全
                                </p>
                            </div>
                            <div class="d3lx">
                                <div class="d3Icon">
                                    <img src="https://bce-cas.bj.bcebos.com/bcd-cas-portal/arrow.svg" alt="" class="d3I" />
                                </div>
                            </div>
                        </li>
                        <li class="d3lLi">
                            <div class="d3l1">
                                <img src="https://bce-cas.bj.bcebos.com/bcd-cas-portal/process4.svg" alt="" class="d3Img" />
                            </div>
                            <div class="d3l2">
                                <p class="d3p">交易完成</p>
                                <p class="d3p2">过户成功，委托想要流程结束</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="dmsBox4">
            <div class="db4Box">
                <div class="d4bTop">
                    <p class="d4t1">常见问题</p>
                    <p class="d4t2">域名委托想要常见问题</p>
                </div>
                <div class="d4bList">
                    <ul>
                        <li class="d4lLi" v-for="item in 4" :key="item">
                            <div class="d4l1">
                                <p class="d1n">{{ item }}</p>
                                <p class="d1p">什么事域名委托想要？</p>
                            </div>
                            <div class="d4l2">
                                域名委托想要是威有云为客户提供的一项定制化域名交易服务，
                                即由威有云及其合作伙伴根据客户提出的具体域名想要需求，
                                为客户进行询价、议价、分析、谈判、交易、域名转移等的一系列服务的统称。
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="dmsBox5">
            <div class="db5T">
                <p class="d5tp">发布委托想要需求</p>
                <div class="d5tb">去发布</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DmSale",
};
</script>

<style lang="less" scoped>
.dmSale {
    width: 100%;
    height: 2900px;
    margin: 0 auto;
    margin-top: 87px;
    // 头部图
    .dmsTop {
        width: 100%;
        height: 400px;
        position: relative;
        overflow: hidden;
        .dtBg {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            z-index: -1;
        }
        .dtText {
            width: 294px;
            height: 172px;
            z-index: 5;
            margin: 0 auto;
            margin-top: 100px;
            color: white;
            text-align: center;
            .dt1 {
                width: 100%;
                height: 42px;
                line-height: 42px;
                font-size: 36px;
            }
            .dt2 {
                width: 100%;
                height: 42px;
                line-height: 42px;
                font-size: 14px;
            }
            .dt3 {
                width: 136px;
                height: 40px;
                margin: 0 auto;
                margin-top: 45px;
                line-height: 40px;
                border-radius: 20px;
                font-size: 14px;
                background-color: #7781f1;
            }
        }
    }
    .dmsBox {
        width: 100%;
        height: 600px;
        .dbTop {
            width: 100%;
            height: 50px;
            overflow: hidden;
            background-color: #fff;
            ul {
                width: 100%;
                height: 30px;
                line-height: 30px;
                margin-top: 10px;
                display: flex;
                justify-content: space-around;
                .dbtLi {
                    min-width: 112px;
                    height: 30px;
                    font-size: 14px;
                }
            }
        }
        .dbBox {
            width: 100%;
            height: 550px;
            overflow: hidden;
            position: relative;
            background-color: #f6f9fd;
            .dbCirl1 {
                width: 480px;
                height: 480px;
                border-radius: 50%;
                background-color: #eaf0fd;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 0;
                .dcT1 {
                    width: 280px;
                    height: 108px;
                    background-color: white;
                    position: absolute;
                    top: 20%;
                    left: -67%;
                    .dtp {
                        margin-left: 16px;
                        padding-top: 10px;
                        line-height: 28px;
                        font-size: 13px;
                    }
                    .dts {
                        position: absolute;
                        top: 0;
                        left: 100%;
                        width: 60px;
                        height: 108px;
                        background-color: white;
                        z-index: 55;
                        clip-path: polygon(0 0, 0% 100%, 100% 50%);
                    }
                }
                .dcT2 {
                    width: 280px;
                    height: 86px;
                    background-color: white;
                    position: absolute;
                    top: 70%;
                    left: -62%;
                    .dtp {
                        margin-left: 16px;
                        padding-top: 15px;
                        line-height: 28px;
                        font-size: 13px;
                    }
                    .dts {
                        position: absolute;
                        top: 0;
                        left: 100%;
                        width: 60px;
                        height: 86px;
                        background-color: white;
                        z-index: 55;
                        clip-path: polygon(0 0, 0% 100%, 100% 50%);
                    }
                }
                .dcT3 {
                    width: 280px;
                    height: 86px;
                    background-color: white;
                    position: absolute;
                    top: 50%;
                    right: -70%;
                    .dtp {
                        margin-left: 16px;
                        padding-top: 15px;
                        line-height: 28px;
                        font-size: 13px;
                    }
                    .dts {
                        position: absolute;
                        top: 0;
                        left: -21%;
                        width: 60px;
                        height: 86px;
                        background-color: white;
                        z-index: 55;
                        transform: rotate(180deg);
                        clip-path: polygon(0 0, 0% 100%, 100% 50%);
                    }
                }
                .dbTitle {
                    width: 350px;
                    height: 100px;
                    position: absolute;
                    top: 28%;
                    right: -360px;
                    .dbt {
                        width: 100%;
                        height: 42px;
                        line-height: 42px;
                        font-size: 28px;
                    }
                    .dbt:nth-child(2) {
                        font-weight: 500;
                        padding-bottom: 10px;
                        border-bottom: 5px solid #2468f2;
                    }
                }
            }
            .dbImgs {
                width: 400px;
                height: 224px;
                margin: 0 auto;
                margin-top: 150px;
                z-index: 5;
                position: relative;
                .dbiBg {
                }
                .dbi1,
                .dbi2,
                .dbi3,
                .dbi4,
                .dbi5 {
                    width: 98px;
                    height: 98px;
                    text-align: center;
                    line-height: 98px;
                    border-radius: 50%;
                    background-color: white;
                    color: #2468f2;
                    font-weight: 400;
                    font-size: 28px;
                    opacity: 0.9;
                    position: absolute;
                    top: -50%;
                }
                .dbi2,
                .dbi3 {
                    width: 80px;
                    height: 80px;
                    line-height: 80px;
                    font-size: 22px;
                    position: absolute;
                    top: -55%;
                    right: 0%;
                    opacity: 0.5;
                }
                .dbi3 {
                    position: absolute;
                    top: 45%;
                    right: 10%;
                    opacity: 0.9;
                }
                .dbi4 {
                    position: absolute;
                    top: 60%;
                }
                .dbi5 {
                    position: absolute;
                    top: -20%;
                    right: 10%;
                    .d5Img {
                        margin: 0 auto;
                        margin-top: 20px;
                        display: block;
                    }
                }
            }
        }
    }
    .dmsBox2 {
        width: 100%;
        height: 530px;
        overflow: hidden;
        .db2Box {
            width: 1280px;
            height: 90%;
            margin: 0 auto;
            margin-top: 20px;
            .d2bTop {
                width: 30%;
                height: 80px;
                line-height: 40px;
                text-align: center;
                margin: 0 auto;
                .d2t1 {
                    width: 100%;
                    height: 40px;
                    font-size: 28px;
                }
                .d2t2 {
                    width: 100%;
                    height: 40px;
                    font-size: 14px;
                }
            }
            .d2bList {
                width: 100%;
                height: 307px;
                margin-top: 50px;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-around;
                    .d2lLi {
                        width: 280px;
                        height: 100%;
                        box-shadow: 1px 1px 10px 1px gainsboro;
                        border-radius: 10px;
                        .d2l1 {
                            width: 100%;
                            height: 50%;
                            border-top-left-radius: 10px;
                            border-top-right-radius: 10px;
                            overflow: hidden;
                            .d2lImg {
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .d2l2 {
                            width: 100%;
                            height: 70px;
                            margin-top: 40px;
                            text-align: center;
                            .d2p {
                                width: 100%;
                                height: 18px;
                                line-height: 18px;
                                font-size: 18px;
                            }
                            .d2p2 {
                                width: 100%;
                                height: 42px;
                                font-size: 14px;
                                margin-top: 10px;
                                color: gray;
                            }
                        }
                    }
                }
            }
        }
    }
    .dmsBox3 {
        width: 100%;
        height: 445px;
        margin-top: 20px;
        background-color: #f6f9fd;
        .db3Box {
            width: 1280px;
            height: 100%;
            margin: 0 auto;
            .d3bTop {
                width: 30%;
                height: 80px;
                line-height: 40px;
                text-align: center;
                margin: 0 auto;
                .d3t1 {
                    width: 100%;
                    height: 40px;
                    font-size: 28px;
                }
                .d3t2 {
                    width: 100%;
                    height: 40px;
                    font-size: 14px;
                }
            }
            .d3bList {
                width: 100%;
                height: 217px;
                margin-top: 50px;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-around;
                    .d3lLi {
                        width: 276px;
                        height: 217px;
                        position: relative;
                        .d3l1 {
                            width: 100%;
                            height: 40%;
                            .d3Img {
                                display: block;
                                margin: 0 auto;
                            }
                        }
                        .d3l2 {
                            width: 100%;
                            height: 70px;
                            margin-top: 30px;
                            text-align: center;
                            .d3p {
                                width: 100%;
                                height: 18px;
                                line-height: 18px;
                                font-size: 16px;
                            }
                            .d3p2 {
                                width: 100%;
                                height: 42px;
                                margin-top: 10px;
                                font-size: 14px;
                            }
                        }
                        .d3lx {
                            width: 115px;
                            border-top: 2px dashed #9c9c9c;
                            position: absolute;
                            top: 45px;
                            left: 100%;
                            transform: translateX(-50%);
                            .d3Icon {
                                position: relative;
                                top: -12px;
                                left: 40px;
                            }
                        }
                    }
                }
            }
        }
    }
    .dmsBox4 {
        width: 100%;
        height: 660px;
        margin-top: 20px;
        .db4Box {
            width: 1000px;
            height: 100%;
            margin: 0 auto;
            .d4bTop {
                width: 30%;
                height: 80px;
                line-height: 40px;
                text-align: center;
                margin: 0 auto;
                .d4t1 {
                    width: 100%;
                    height: 40px;
                    font-size: 28px;
                }
                .d4t2 {
                    width: 100%;
                    height: 40px;
                    font-size: 14px;
                }
            }
            .d4bList {
                width: 100%;
                height: 500px;
                margin-top: 40px;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-around;
                    flex-wrap: wrap;
                    .d4lLi {
                        width: 100%;
                        height: 87px;
                        .d4l1 {
                            width: 100%;
                            height: 28px;
                            line-height: 28px;
                            .d1n {
                                width: 28px;
                                height: 28px;
                                text-align: center;
                                border-radius: 50%;
                                color: white;
                                background-color: #2468f2;
                                float: left;
                            }
                            .d1p {
                                min-width: 180px;
                                height: 28px;
                                margin-left: 20px;
                                font-size: 18px;
                                float: left;
                            }
                        }
                        .d4l2 {
                            width: 95%;
                            height: 42px;
                            font-size: 14px;
                            color: gray;
                            margin-left: 5%;
                            margin-top: 15px;
                        }
                    }
                }
            }
        }
    }
    .dmsBox5 {
        width: 100%;
        height: 200px;
        background: url("https://bce-cas.bj.bcebos.com/bcd-cas-portal/domain_publish_bg.png");
        overflow: hidden;
        .db5T {
            width: 192px;
            height: 91px;
            margin: 0 auto;
            margin-top: 50px;
            text-align: center;
            .d5tp {
                width: 100%;
                height: 42px;
                line-height: 42px;
                font-size: 24px;
            }
            .d5tb {
                width: 112px;
                height: 33px;
                line-height: 33px;
                border-radius: 20px;
                margin: 0 auto;
                margin-top: 10px;
                color: white;
                background-color: #2468f2;
            }
        }
    }
}
</style>
